import request from 'app/http'

const getPaymentOrderCheck = () => {
  return request.get('/payment/order/check')
}

const getPaymentOrderPriceShow = () => {
  return request.get('/payment/order/priceshow')
}

export {
  getPaymentOrderCheck,
  getPaymentOrderPriceShow
}
