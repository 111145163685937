import store from '@/store'
import router from '@/router'
import emitter from '@/app/emitter.js'

/**
 *
 * @param {*} params 参数，包含to对象表示页面跳转可以包含route里面的参数， cb参数表示回调自己自由操作
 * @returns
 */
export function upgradeAction(params) {
  const loginState = store.getters.isLogin
  if (loginState) {
    if (params.to) {
      router.push({
        ...params.to
      })
    } else {
      params.cb()
    }
  } else {
    // 弹出login窗
    emitter.emit('showLogin')
  }
}
