<template>
  <div>
    <div class="box1" v-if="showReport">
      <div class="title"></div>
      <p class="desc">{{$t('app.intro.selectedReports')}}</p>
    <div class="report">
      <swiper ref="mySwiper"
      class="reported-swiper"
      :slidesPerView="swiperOptions.slidesPerView"
      :navigation="swiperOptions.navigation"
      :space-between="10"
      :modules="modules"
      >
        <swiper-slide v-for="item in reportData" :key="item.id" class="card" @click="toReport(item.id)">
          <div class="headline">
            <span>{{item.file_type}}</span>
            <p>{{item.filename}}</p>
          </div>
          <div class="infos">
            <div ref="myScore" class="score" :style="{'border':`1px solid ${waves[level(item.score)]}`}"></div>
            <div class="details">
              <div class="system">
                <span></span>
                <p>{{item.expect_os_env[0]}} {{item.expect_os_env[1]}} {{item.expect_os_env[2]}}位</p>
              </div>
              <div class="time">
                <span></span>
                <p>{{item.added_on}}</p>
              </div>
            </div>
          </div>
          <div class="tags">
            <div class="tag" v-for="tag in item.tag_info" :style="{'color':`${tagColors[tagLevel(tag.severity)]}`, border: `1px solid ${tagColors[tagLevel(tag.severity)]}`, background: `${tagBGColors[tagLevel(tag.severity)]}`}" :key="tag.name">{{ $isZh() ? tag.tagzhcn : tag.tagenus }}</div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev swiper-button-white"></div>
      <div class="swiper-button-next swiper-button-white"></div>
    </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import { getHomeTask } from 'services/home'
import * as echarts from 'echarts'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      reportData: [],
      swiperOptions: {
        slidesPerView: 4, // 显示个数
        direction: 'horizontal',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        spaceBetween: '1%'
      },
      modules: [Navigation],
      scoreInstances: [],
      scoreColors: {
        low: '#3EAE89',
        middle: '#E69F32',
        high: '#CF605B'
      },
      tagColors: {
        low: '#3EAE89',
        middle: '#E69F32',
        high: '#F57B75'
      },
      tagBGColors: {
        low: 'rgba(62,174,137,0.1)',
        middle: 'rgba(230,159,50,0.1',
        high: 'rgba(207, 96, 91, 0.1'
      },
      waves: {
        low: 'rgba(62,174,137,0.4)',
        middle: 'rgba(230,159,50,0.4)',
        high: 'rgba(207, 96, 91, 0.4)'
      }
    }
  },
  props: {
    showReport: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    getHomeTask().then(res => {
      this.reportData = res
      // this.scoreInstances = echarts.init(this.$refs.score)
      this.$nextTick(() => {
        const dom = document.querySelectorAll('.score')
        Array.from(dom).map((ref, index) => {
          // const instance = echarts.init(ref, null, { devicePixelRatio: 2 })
          const instance = echarts.init(ref, null, { renderer: 'svg' })
          const score = this.reportData[index].score
          const level = this.level(score)
          const opt = {
            series: [{
              type: 'liquidFill',
              data: [score / 10, {
                value: score / 10,
                phase: Math.PI
              }],
              backgroundStyle: {
                color: 'transparent'
              },
              color: [this.scoreColors[level], this.waves[level]],
              radius: '98%', // 半径大小
              center: ['50%', '50%'],
              phase: 0,
              period: 4000,
              waveLength: '100%',
              animationDurationUpdate: 2000,
              label: {
                show: true,
                color: 'white',
                insideColor: 'white',
                fontSize: 20,
                fontWeight: 'bold',

                align: 'center',
                baseline: 'middle',
                position: 'inside'
              },
              outline: {
                show: true,
                borderDistance: 0,
                itemStyle: {
                  // color: 'black',
                  borderColor: this.scoreColors[level],
                  borderWidth: 2
                  // shadowBlur: 3,
                  // shadowColor: 'rgba(0, 0, 0, 0.25)'
                }
              }
            }]
          }
          instance.setOption(opt)
        })
      })
    })
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    next() {
      this.$refs.mySwiper.$swiper.slideNext()
    },
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev()
    },
    toDashboard () {
      window.open('dashboard')
    },
    toReport (id) {
      window.open(`report/${id}`)
    },
    level(score = 0) {
      score = Number(score)
      if (score < 5) {
        return 'low'
      } else if (score < 7.5 && score >= 5) {
        return 'middle'
      } else {
        return 'high'
      }
    },
    tagLevel(score = 0) {
      score = Number(score)
      if (score <= 1) {
        return 'low'
      } else if (score === 2) {
        return 'middle'
      } else {
        return 'high'
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.swiper-container  {
  width: 100%;
}
.report  {
  padding: 0 30px;
  position: relative;
  padding-bottom: 10px;
  .reported-swiper {
    width: 100%;
    .swpier-slide {
      width: 25%;
    }
  }
}
.swiper-button-prev:after, .swiper-button-next:after  {
  font-size: 25px !important;
}
.swiper-button-next  {
  color: var(--color-text-1);
  right: -5px;
  &:hover  {
    color: #1FE2A9;
  }
}
.swiper-button-prev  {
  left: -5px;
  color: var(--color-text-1);
  &:hover  {
    color: #1FE2A9;
  }
}
.box1  {
  width: 100%;
  margin-top: 100px;
  position: relative;
  &-bg  {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 100px;
  }
  .title  {
    width: 178px;
    height: 58px;
    margin: 0 auto;
    background-image: url('~@/assets/img/home/home-selected-report.png');
    background-position: 0 50%;
    background-size: 100% 100%;
  }
  .desc  {
    opacity: 0.7;
    font-size: 14px;
    color: var(--color-text-1);
    font-weight: 400;
    text-align: center;
    margin: 20px 0 70px 0;
  }
  .card  {
    width: 378px;
    background: #1B1D21;
    border: 1px solid #3D4852;
    border-radius: 2px;
    box-sizing: border-box;
    height: 200px;
    cursor: pointer;
    &:hover {
      border: 1px solid #00ab7a;
    }
    padding: 10px 12px;.headline  {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #283440;
      span  {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        text-align: center;
        padding: 2px 6px;
        font-size: 14px;
        color: var(--color-text-1);
        letter-spacing: 0;
        font-weight: 400;
        background: #2C3440;
        border-radius: 2px;
      }
      p  {
        padding-left: 8px;
        line-height: 20px;
        flex: 1;
        margin: 0;
        height: 20px;
        font-size: 14px;
        color: var(--color-text-1);
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .infos  {
      margin-top: 13px;
      display: flex;
      .score  {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        padding: 2px;
      }
      .details  {
        padding-left: 8px;
        .system, .time  {
          display: flex;
          align-items: center;
          span  {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url('~@/assets/img/home/home-window-logo.png') no-repeat;
            background-size: 100% 100%;
          }
          p  {
            padding-left: 5px;
            width: 140px;
            font-size: 14px;
            color: var(--color-text-2);
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .time  {
          span  {
            background: url('~@/assets/img/home/home-time-logo.png') no-repeat;
            background-size: 100% 100%;
          }
          p  {
            margin: 0;
          }
        }
      }
    }
    .tags  {
      margin-top: 6px;
      height: 50px;
      overflow: hidden;
      .tag  {
        display: inline-block;
        padding: 3px 6px;
        background: #26282E;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        margin: 0 4px 4px 0;
      }
    }
  }
  &-content  {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &-card  {
    height: 204px;
    width: 378px;
    box-sizing: border-box;
    padding: 27px 22px;
    margin-bottom: 24px;
    background: rgba(23, 32, 87, 0.8);
    box-shadow: 0 2px 4px 0 #020737;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    transition: all 0.5s;
    &:hover  {
      background-image: linear-gradient(180deg, RGBA(19, 37, 122, 0.7) 0%, RGBA(21, 41, 108, 0.7) 100%);
      box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.035), inset 0 0 10px 0 RGBA(94, 125, 247, 0.7);
      border-radius: 4px;
    }
    &__icon  {
      width: 29px;
      height: 38px;
      display: inline-block;
      vertical-align: middle;
    }
    &__number  {
      height: 28px;
      width: 66px;
      line-height: 28px;
      text-align: center;
      background: #CF605B;
      border-radius: 4px;
      font-family: Verdana-Bold;
      position: absolute;
      right: 22px;
      top: 30px;
      &--low  {
        background: #3eae89;
      }
      &--middle  {
        background: #e69f34;
      }
      &--high  {
        background: #cf605b;
      }
    }
    &__info  {
      margin-left: 13px;
      display: inline-block;
      vertical-align: middle;
    }
    &__title  {
      line-height: 20px;
      margin-bottom: 6px;
    }
    &__date  {
      line-height: 20px;
    }
    &__tag  {
      margin-top: 18px;
      height: 60px;
      overflow: hidden;&--item  {
        line-height: 22px;
        padding: 0 9px;
        border: 1px solid #FF8D87;
        color: #FF8D87;
        border-radius: 4px;
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 8px;
      }
      &--low  {
        border-color: #8EA4FF;
        color: #8EA4FF;
      }
      &--middle  {
        border-color: #C2AA6F;
        color: #C2AA6F;
      }
      &--high  {
        border-color: #FF8D87;
        color: #FF8D87;
      }
    }
    &__bottom  {
      width: 100%;
      height: 35px;
      line-height: 35px;
      background: rgba(98, 97, 252, 0.1);
      border-radius: 0 0 4px 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      & span  {
        margin-left: 22px;
      }
    }
  }
}
[lang='en'] {
  .box1  {
    .title {
      width: 648px;
      background-image: url('~@/assets/img/home/home-selected-report-en.png');
    }
  }
}
[data-theme='light'] {
  &[lang='en'] {
    .box1  {
      .title {
        background-image: url('~@/assets/img/home/home-selected-report-light-en.png');
      }
    }
  }
  .box1  {
    .title {
      background-image: url('~@/assets/img/home/home-selected-report-light.png');
    }
    .card  {
      background: #ffffff;
      border: 1px solid #E8EAED;
      backdrop-filter: blur(27.2px);
      box-shadow: 0px 0px 24px 0px #0F1110;
      box-shadow: none;
      &:hover {
        border: 1px solid #00AB7A;
      }
      .headline {
        border-color: #E8EAED;
        span {
          background: #F2F4F5;
        }
      }
    }
  }
}
</style>
