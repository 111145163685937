<template>
  <div class="home">
    <div class="container">
      <div class="box1">
        <div style="width: 550px;padding-top: 50px;">
          <div class="box1-title1">{{$t('app.intro.title')}}</div>
          <div class="box1-title2">{{$t('app.intro.logo')}}</div>
          <div class="box1-text">{{$t('app.intro.desc')}}</div>
          <div class="box1-btns">
            <div class="box1-btns-btn" @click="open('/detect')">{{$t('app.intro.try')}}</div>
            <div class="box1-btns-btn box1-btns-plain" @click="handleDownloadSample(`/home/download_whitepaper?lang=${$isZh() ? 'zh' : 'en'}`,$isZh() ? '沙箱云白皮书.pdf' : '360_ATA_Cloud_Whitepaper.pdf')">{{$t('app.intro.whitePapper')}}</div>
            <div class="box1-btns-btn box1-btns-plain" @click="handleDownloadSample(`home/download_colorpage?lang=${$isZh() ? 'zh' : 'en'}`,$isZh() ? '沙箱云彩页.pdf': '360_ATA_Cloud_Flyer.pdf')">{{$t('app.intro.colorPage')}}</div>
          </div>
        </div>
        <video-player class="video-wrap" :options="data.videoOptions"/>
      </div>
      <div class="customer-choice">
        <div class="title"></div>
        <p>{{$t('app.intro.customer')}}</p>
        <p class="margintop">{{$t('app.intro.order')}}</p>
        <div class="customers">
          <div class="item" v-for="(item, index) in data.customers" :style="getCustomerBg(item)" :key="index">
            {{item.font}}
          </div>
        </div>
      </div>
      <div class="product-advantage">
        <div class="title"></div>
        <div class="main">
          <div class="left">
            <div class="active_bg" ref="tabBg"></div>
            <div class="items">
              <div class="item" @mouseenter="tabMouseenter" :data-index="index" v-for="(item,index) in data.box3List" :key="index" :class="{'active':data.tabActive === index}">
                <span>></span>
                {{$t(item.title)}}
              </div>
            </div>
          </div>
          <div class="right" :style="getBg(data.tabActive, 'ps')">
            <div class="item">
              <p class="headline">{{$t(data.box3List[data.tabActive].title)}}</p>
              <p class="content">{{$t(data.box3List[data.tabActive].content)}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="typical-scenarios">
        <div class="title"></div>
        <div class="tabs">
          <div class="tsActive" ref="tabTs"></div>
          <div class="tab" @mouseenter="tabTsMouseenter" :data-index="index" v-for="(item, index) in data.typicalScenarisList" :key="index" :class="{'active': index === data.typicalIndex}"><span>{{$t(item.title)}}</span></div>
        </div>
        <div class="card" :style="getBg(data.typicalIndex, 'ts')">
          <p class="headline">{{$t(data.typicalScenarisList[data.typicalIndex].title)}}</p>
          <p class="content">{{$t(data.typicalScenarisList[data.typicalIndex].content)}}</p>
        </div>
      </div>
      <selected-reports></selected-reports>
      <div class="box4">
        <div class="title"></div>
        <div class="box4-content">
          <div class="box4-item box4-item1">
            <div class="box4-item__title">{{$t('app.intro.servicePrice.free.title')}}</div>
            <div :class="{en_content : !lang }" class="box4-item__content">{{$t('app.intro.servicePrice.free.desc')}}</div>
            <div class="box4-item__list">
              <div class="box4-item__list--content">{{$t('app.intro.servicePrice.free.characteristic')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.free.item1')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.free.item2')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.free.item3')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.free.item4')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.free.item5')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.free.item6')}}</div>
            </div>
            <div class="box4-item__btn" @click="open('/detect')">{{$t('app.intro.servicePrice.free.btn')}}</div>
          </div>
          <div class="box4-item box4-item2">
            <div class="box4-item__title">{{$t('app.intro.servicePrice.professional.title')}}</div>
            <div :class="{en_content : !lang }" class="box4-item__content">{{$t('app.intro.servicePrice.professional.desc')}}</div>
            <div class="box4-item__list">
              <div class="box4-item__list--content">{{$t('app.intro.servicePrice.professional.characteristic')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professional.item1')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professional.item2')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professional.item3')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professional.item4')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professional.item5')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professional.item6')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professional.item7')}}</div>
            </div>
            <div class="box4-item__btn" @click="open('/price')">{{$t('app.intro.servicePrice.professional.btn')}}</div>
          </div>
          <div class="box4-item box4-item3">
            <div class="box4-item__title">{{$t('app.intro.servicePrice.professionalPlus.title')}}</div>
            <div :class="{en_content : !lang }" class="box4-item__content">{{$t('app.intro.servicePrice.professionalPlus.desc')}}</div>
            <div class="box4-item__list">
              <div class="box4-item__list--content">{{$t('app.intro.servicePrice.professionalPlus.characteristic')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professionalPlus.item1')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professionalPlus.item2')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professionalPlus.item3')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professionalPlus.item4')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professionalPlus.item5')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professionalPlus.item6')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.professionalPlus.item7')}}</div>
            </div>
            <div class="box4-item__btn" @click="open('/price')">{{$t('app.intro.servicePrice.professionalPlus.btn')}}</div>
          </div>
          <div class="box4-item box4-item4">
            <div class="box4-item__title">{{$t('app.intro.servicePrice.enterprise.title')}}</div>
            <div :class="{en_content : !lang }" class="box4-item__content">{{$t('app.intro.servicePrice.enterprise.desc')}}</div>
            <div class="box4-item__list">
              <div class="box4-item__list--content">{{$t('app.intro.servicePrice.enterprise.characteristic')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.enterprise.item1')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.enterprise.item2')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.enterprise.item3')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.enterprise.item4')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.enterprise.item5')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.enterprise.item6')}}</div>
              <div class="box4-item__list--li"><el-icon><Check /></el-icon>{{$t('app.intro.servicePrice.enterprise.item7')}}</div>
            </div>
            <div class="box4-item__btn" @click="open('/price')">{{$t('app.intro.servicePrice.enterprise.btn')}}</div>
          </div>
        </div>
      </div>
    </div>
    <backTo-top></backTo-top>
  </div>
</template>
<script setup>
import { onMounted, onBeforeUnmount, reactive, computed, ref, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import VideoPlayer from '../component/video'
import SelectedReports from '../component/selectedReports'
import { downloadSample } from 'services/report'
import { download } from 'utils/tools'
import { Check } from '@element-plus/icons-vue'
import { isDark, watchSwitchTheme } from 'app/style'
import { isZh, t } from 'app/i18n'

const gProperties = getCurrentInstance().appContext.config.globalProperties
const store = useStore()

const tabTs = ref()
const tabBg = ref()

const data = reactive({
  tabActive: 0,
  typicalIndex: 0,
  box3List: [
    {
      title: 'app.intro.productAdvantage.title1',
      content: 'app.intro.productAdvantage.content1',
      img: 'home3-box3-img1'
    },
    {
      title: 'app.intro.productAdvantage.title2',
      content: 'app.intro.productAdvantage.content2',
      img: 'home3-box3-img2'
    },
    {
      title: 'app.intro.productAdvantage.title3',
      content: 'app.intro.productAdvantage.content3',
      img: 'home3-box3-img3'
    },
    {
      title: 'app.intro.productAdvantage.title4',
      content: 'app.intro.productAdvantage.content4',
      img: 'home3-box3-img4'
    },
    {
      title: 'app.intro.productAdvantage.title5',
      content: 'app.intro.productAdvantage.content5',
      img: 'home3-box3-img5'
    },
    {
      title: 'app.intro.productAdvantage.title6',
      content: 'app.intro.productAdvantage.content6',
      img: 'home3-box3-img6'
    }
  ],
  typicalScenarisList: [
    {
      title: 'app.intro.typicalScenarisList.title1',
      content: 'app.intro.typicalScenarisList.content1'
    },
    {
      title: 'app.intro.typicalScenarisList.title2',
      content: 'app.intro.typicalScenarisList.content2'
    },
    {
      title: 'app.intro.typicalScenarisList.title3',
      content: 'app.intro.typicalScenarisList.content3'
    }
  ],
  videoOptions: {
    autoplay: false, // 自动播放
    controls: true, // 用户可以与之交互的控件
    loop: true, // 视频一结束就重新开始
    muted: false, // 默认情况下将使所有音频静音
    aspectRatio: '16:9', // 显示比率
    fullscreen: {
      options: { navigationUI: 'hide' }
    },
    poster: isDark() ? (isZh() ? require('../component/sandbox.png') : require('../component/sandbox-en.png')) : (isZh() ? require('../component/sandbox-light.png') : require('../component/sandbox-light-en.png')),
    sources: [
      {
        src: require('../component/sandbox.mp4'),
        // src: '//vjs.zencdn.net/v/oceans.mp4',
        type: 'video/mp4'
      }
    ]
  },
  customers: [
    {
      type: 'prc',
      height: 28
    },
    {
      type: 'wz',
      height: 30
    },
    {
      type: 'meituan',
      height: 32
    },
    {
      type: 'huawei',
      height: 26
    },
    {
      type: 'zhaoshang',
      height: 32
    },
    {
      type: 'yjzx',
      height: 53
    },
    {
      type: 'zggs',
      height: 31
    },
    {
      type: 'zgyd',
      height: 48
    },
    {
      type: 'midea',
      height: 40
    },
    {
      type: 'scdx',
      height: 36
    }
    // {
    //   font: '加入我们，共建生态'
    // }
  ]
})
const lang = computed(() => {
  return store.getters.language === 'zh'
})

onMounted(() => {
  scrollEvent()
  window.addEventListener('scroll', scrollEvent)
  watchSwitchTheme(() => {
    data.videoOptions.poster = isDark() ? require('../component/sandbox.png') : require('../component/sandbox-light.png')
  })
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', scrollEvent)
})
function scrollEvent () {
  Array.from(document.getElementsByClassName('box3-item')).forEach(item => {
    if (window.innerHeight - item.getBoundingClientRect().top > 300) {
      item.className = 'box3-item box3-normal'
    }
  })
}
function getBg(index, type) {
  return {
    backgroundImage: gProperties.$isDark() ? 'url(' + require(`@/assets/img/home/${type}-${index}.png`) + ')' : 'url(' + require(`@/assets/img/home/${type}-${index}-light.png`) + ')'
  }
}
function getCustomerBg(item) {
  if (!item.type) return ''
  return {
    backgroundImage: gProperties.$isDark() ? 'url(' + require(`@/assets/img/home/home-${item.type}.png`) + ')' : 'url(' + require(`@/assets/img/home/home-${item.type}-light.png`) + ')',
    backgroundSize: `auto ${item.height}px`
  }
}
function open (to) {
  window.open(to)
}
function handleDownloadSample(url, filename) {
  downloadSample(url.replace('/api/v1', ''))
    .then((res) => {
      download({
        content: res.data,
        type: 'application/octet-stream',
        filename
      })
    })
    .catch((err) => {
      if (err.message !== 'Request failed with status code 429') {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('other.views.permise.uploadStatus1'),
          duration: 1000
        })
      }
    })
}
function tabMouseenter (e) {
  data.tabActive = Number(e.target.dataset.index)
  tabBg.value.style.top = `${e.target.dataset.index * e.target.offsetHeight}px`
}
function tabTsMouseenter(e) {
  data.typicalIndex = Number(e.target.dataset.index)
  tabTs.value.style.left = `${e.target.dataset.index * e.target.offsetWidth}px`
}
</script>

<style lang="scss" scoped>
[lang='en'] {
  .home {
    .customer-choice {
      .title {
        width: 256px;
        background-image: url('~@/assets/img/home/home-customer-choice-en.png');
      }
    }
  }
  .typical-scenarios {
    .title {
      width: 371px;
      background-image: url('~@/assets/img/home/home-typical-scenarios-en.png');
    }
  }
  .box4 {
    .title {
      width: 302px;
      background-image: url('~@/assets/img/home/home-service-price-en.png');
    }
  }
  .product-advantage {
    .title {
      width: 421px;
      background-image: url('~@/assets/img/home/home-product-advantage-en.png');
    }
  }
}
.home  {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: #101116;
  min-width: 1220px;
  background: url('~@/assets/img/home/home-box1-bg.png') center top/100% 720px no-repeat;
}
.container  {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: var(--color-text-1);
  width: 1340px;
  margin: 0 auto;
}
.box1  {
  height: 400px;
  display: flex;
  justify-content: space-between;
  padding-top: 140px;
  position: relative;
  box-sizing: content-box;
  .video-wrap {
    box-sizing: border-box;
    border: 6px solid #062A28;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    width: 700px;
    height: 399px;
    :deep(.video-js ) {
      .vjs-big-play-button {
        margin-left: -1.25em;
      }
      .vjs-volume-level:before {
        font-size: 12px;
        top: -4px;
        right: -6px;
      }
      .vjs-play-progress:before {
        font-size: 12px;
        top: -4px;
        right: -6px;
      }
    }
  }
  &-title1  {
    font-weight: bold;
    font-size: 50px;
    color: var(--color-text-1);
    letter-spacing: 2.2px;
    position: relative;
  }
  &-line  {
    height: 6px;
    width: 75px;
    background-image: linear-gradient(to left, rgba(63, 114, 255, 0.6), rgba(62, 156, 246, 1));
    margin-top: 20px;
    position: relative;
  }
  &-title2  {
    margin-top: 30px;
    font-size: 22px;
    color: var(--color-text-1);
    opacity: 0.8;
    position: relative;
  }
  &-text  {
    margin-top: 30px;
    width: 545px;
    font-weight: 300;
    font-size: 14px;
    color: #B7B9BB;
    opacity: 0.8;
    line-height: 26px;
    position: relative;
  }
  &-btns  {
    display: flex;
    margin-top: 58px;
    &-btn  {
      height: 40px;
      width: 118px;
      text-align: center;
      line-height: 40px;

      background: #009E71;
      color: #ffffff;
      border-radius: 2px;
      font-size: 14px;
      margin-right: 16px;
      cursor: pointer;
      position: relative;
      font-weight: 500;
      transition: all 0.5s;
      &:hover  {
        filter: brightness(1.2);
      }
    }
    &-plain  {
      border: 1px solid #00B682;
      border-radius: 2px;
      color: #00B682;
      background: none;
    }
  }
  &-bg  {
    width: 65%;
    max-width: 866px;
    height: 724px;
    position: absolute;
    right: -10%;
    top: 0;
    overflow-x: hidden;
    background-image: url('~@/assets/img/home/home-box1-sandbox.png');
    background-size: 110%;

    background-repeat: no-repeat;
  }
}
.customer-choice  {
  margin: 130px 0 100px;
  .title  {
    width: 260px;
    height: 53px;
    margin: 0 auto;
    background-image: url('~@/assets/img/home/home-customer-choice.png');
    background-position: 0 50%;
    background-size: 100% 100%;
  }
  p  {
    font-size: 14px;
    color: var(--color-text-1);
    font-weight: 400;
    opacity: 0.7;
    text-align: center;
    margin: 24px 0 12px 0;
  }
  .margintop {
    margin-top: 12px;
  }
  .customers  {
    margin: 25px auto 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item  {
      width: 256px;
      // width: 224px;
      height: 81px;
      margin-top: 20px;
      background: rgba(40, 49, 57, 0.6);
      box-shadow: 0px 0px 24px 0px rgba(15, 17, 16, 1);
      border-radius: 4px;
      background-position: 50% 50%;
      background-size: 50%;
      background-repeat: no-repeat;
      font-size: 13px;
      align-items: center;
      color: rgba(255,255,255,0.4);
      display: flex;
      justify-content: center;
    }
  }
}
.product-advantage  {
  .title  {
    width: 178px;
    height: 58px;
    margin: 0 auto;
    background-image: url('~@/assets/img/home/home-product-advantage.png');
    background-position: 0 50%;
    background-size: 100% 100%;
  }
  .main  {
    margin-top: 50px;
    display: flex;
    height: 300px;
    .left  {
      width: 20%;
      position: relative;
      .active_bg  {
        box-sizing: border-box;
        background: #1B1D21;
        width: 100%;
        height: 50px;
        border-right: 2px solid rgb(31, 226, 169);
        // box-shadow: 0px 0px 24px 0px rgba(15, 17, 16, 1);
        position: absolute;
        transition: top 0.3s;
        opacity: 1 !important;
        left: 0;
        top: 0;
        z-index: 0;
        cursor: pointer;
        z-index: 1;
        span  {
          color: transparent;
          position: relative;
          top: -1px;
          color: #1FE2A9;
        }
      }
      .items  {
        position: absolute;
        z-index: 2;
        width: 100%;
      }
      .item  {
        height: 50px;
        // opacity: 0.7;
        font-size: 14px;
        color: var(--color-text-1);
        font-weight: 400;
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
        padding: 0 20px;
        line-height: 50px;
        border-right: 2px solid rgba(31, 226, 169, 0.2);
        cursor: pointer;
        // box-shadow: inset -1px 0px 0px 0px rgba(0, 0, 0, 0.5);
        &:hover  {
          color: #1FE2A9;
          opacity: 1;
        }
        span  {
          color: transparent;
          position: relative;
          top: -1px;
          transition: color 0.3s;
        }
      }
      .active  {
        color: #1FE2A9;
        font-weight:600;
        // opacity: 1;
        span  {
          color: #1FE2A9;
        }
      }
    }
    .right  {
      width: 80%;
      padding: 40px 0 0 60px;
      background: #1B1D21;
      box-shadow: 0px 0px 24px 0px rgba(15, 17, 16, 1);
      border-radius: 0 2px 2px 0;
      background-image: url('~@/assets/img/home/ps-0.png');
      background-position: 100% 70%;
      background-size: 40%;
      background-repeat: no-repeat;
      .headline  {
        font-size: 24px;
        color: #1FE2A9;
        font-weight: 300;
        margin: 0 0 30px 0;
      }
      .content  {
        width: 60%;
        font-size: 14px;
        color: var(--color-text-1);
        line-height: 30px;
        font-weight: 400;
      }
    }
  }
}
.typical-scenarios  {
  margin-top: 100px;
  .title  {
    width: 178px;
    height: 58px;
    margin: 0 auto 24px;
    background-image: url('~@/assets/img/home/home-typical-scenarios.png');
    background-position: 0 50%;
    background-size: 100% 100%;
  }
  .tabs  {
    display: flex;
    height: 130px;
    position: relative;
    .tsActive  {
      width: 33.3%;
      height: 4px;
      background: #1FE2A9;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.5s;
    }
    .tab  {
      flex: 1;
      border-bottom: 4px solid rgba(1, 171, 121, 0.2);
      background-position: 50% 35%;
      background-size: 56px 56px;
      background-repeat: no-repeat;
      font-size: 16px;
      color: var(--color-text-1);
      text-align: center;
      line-height: 200px;
      font-weight: 300;
      cursor: pointer;
      span  {
        opacity: 0.7;
      }
      &:nth-child(2)  {
        background-image: url('~@/assets/img/home/home-typical-scenarios-icon1.png');
      }
      &:nth-child(3)  {
        background-image: url('~@/assets/img/home/home-typical-scenarios-icon2.png');
      }
      &:nth-child(4)  {
        background-image: url('~@/assets/img/home/home-typical-scenarios-icon3.png');
      }
    }
    .active  {
      color: #1FE2A9;
      font-weight: 600;
      span  {
        opacity: 1;
      }
    }
  }
  .card  {
    width: 100%;
    height: 300px;
    margin-top: 25px;
    background: #1B1D21;
    box-shadow: 0px 0px 24px 0px rgba(15, 17, 16, 1);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 50px 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    .headline  {
      margin-top: 5px;
      font-size: 24px;
      color: #1FE2A9;
      font-weight: 500;
    }
    .content  {
      width: 70%;
      font-size: 14px;
      color: var(--color-text-1);
      line-height: 30px;
      font-weight: 300;
      margin-top: 25px;
    }
  }
}
.box4  {
  margin: 100px 0 60px 0;
  .title  {
    width: 178px;
    height: 58px;
    margin: 0 auto;
    background-image: url('~@/assets/img/home/home-service-price.png');
    background-position: 0 50%;
    background-size: 100% 100%;
  }
  &-content  {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
  &-item  {
    width: 324px;
    box-sizing: border-box;
    background: #1B1D21;
    box-shadow: 0px 0px 24px 0px rgba(15, 17, 16, 1);
    border-radius: 2px;
    position: relative;
    transition: all 0.5s;
    padding-bottom: 70px;
    &:hover  {
      box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.05);
      background: #23262b;
    }
    &:nth-child(1) &__title {
      background-image: url('~@/assets/img/home/home-servicePrice-mf.png');
    }
    &:nth-child(2) &__title {
      background-image: url('~@/assets/img/home/home-servicePrice-zy.png');
    }
    &:nth-child(3) &__title {
      background-image: url('~@/assets/img/home/home-servicePrice-zyzq.png');
    }
    &:nth-child(4) &__title {
      background-image: url('~@/assets/img/home/home-servicePrice-qy.png');
    }
    &:nth-child(1) i {
      color: #00B682;
    }
    &:nth-child(2) i {
      color: #C6C6C6;
    }
    &:nth-child(3) i {
      color: #C8A87A;
    }
    &:nth-child(4) i {
      color: #FFF0CE;
    }
    &:nth-child(1) &__btn {
      background: #009e71;
      color: var(--color-text-1);
      &:hover  {
        background: #00ad7c;
        color: var(--color-text-1);
      }
    }
    &:nth-child(2) &__btn {
      border: 1px solid #C6C6C6;
      color: #C6C6C6;
      &:hover  {
        border: 1px solid #fafafa;
        color: #fafafa;
      }
    }
    &:nth-child(3) &__btn {
      border: 1px solid #C8A87A;
      color: #C8A87A;
      &:hover  {
        border: 1px solid #fad298;
        color: #fad298;
      }
    }
    &:nth-child(4) &__btn {
      border: 1px solid #E6D8B9;
      color: #E6D8B9;
      &:hover  {
        border: 1px solid #fff0ce;
        color: #fff0ce;
      }
    }
    &__title  {
      height: 70px;
      width: 100%;
      line-height: 70px;
      font-size: 18px;
      text-align: center;
      transition: all 0.5s;
      background-size: 100% 100%;
      font-weight: 500;
    }
    &__content  {
      padding: 20px 0;
      margin: 0 30px;
      line-height: 30px;
      border-bottom: 1px solid #3D4852;
      height: 240px;
      opacity: 0.8;
      font-size: 14px;
      color: var(--color-text-1);
      font-weight: 400;
      box-sizing: content-box;
    }
    .en_content {
      height: 446px !important;
    }
    &__list  {
      padding: 20px 0;
      margin: 0 30px;
      &--content  {
        font-size: 12px;
        color: #A6A6A6;
        font-weight: 500;
      }
      &--li  {
        margin: 20px 0;
        margin-left: 34px;
        line-height: 24px;
        font-size: 14px;
        color: var(--color-text-1);
        opacity: 0.8;
        position: relative;
        font-weight: 400;
        i  {
          position: absolute;
          left: -34px;
          font-size: 18px;
          top: 2px;
        }
      }
    }
    &__btn  {
      height: 40px;
      width: 116px;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      bottom: 30px;
      font-family: PingFangSC-Medium;
      left: calc(50% - 58px);
      border-radius: 20px;
      background: transparent;
      cursor: pointer;
    }
  }
}
.home  {
  &-footer  {
    height: 60px;
    background: #1B1D21;
    box-shadow: inset 0px 1px 0px 0px rgba(38, 40, 51, 1);
    color: #fff !important;
    padding-top: 3px;
    box-sizing: border-box;
    & :deep(.footer-wrap)  {
      margin-top: 18px;
      height: 21px;
      .copyright {
        color: var(--color-text-2) !important;
        .copyright-link  {
          color: var(--color-text-2) !important;
        }
      }
    }
  }
}

[data-theme='light'] {
  &[lang='en'] {
  .home {
    .customer-choice {
      .title {
        background-image: url('~@/assets/img/home/home-customer-choice-light-en.png');
      }
    }
  }
  .typical-scenarios {
    .title {
      background-image: url('~@/assets/img/home/home-typical-scenarios-light-en.png');
    }
  }
  .box4 {
    .title {
      background-image: url('~@/assets/img/home/home-service-price-light-en.png');
    }
  }
  .product-advantage {
    .title {
      background-image: url('~@/assets/img/home/home-product-advantage-light-en.png');
    }
  }
}
  .home  {
    background: url('~@/assets/img/home/home-box1-bg-light.png') center 56px/100% 720px no-repeat;
    background-color: #F8F9FA;
  }
  .box1  {
    .video-wrap {
      border: 6px solid #F8FFFF;
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
    }
    &-title1  {
      color: var(--color-text-1);
    }
    &-line  {
      background-image: linear-gradient(to left, rgba(63, 114, 255, 0.6), rgba(62, 156, 246, 1));
    }
    &-title2  {
      color: var(--color-text-1);
    }
    &-text  {
      color: #525966;
    }
    &-btns  {
      &-btn  {
        background: #009E71;
        &:hover  {
          filter: brightness(1.2);
        }
      }
      &-plain  {
        border: 1px solid #00B682;
        color: #00B682;
        background: #fff;
      }
    }
    &-bg  {
      background-image: url('~@/assets/img/home/home-box1-sandbox-light.png');
      background-size: 110%;
      background-repeat: no-repeat;
    }
  }
  .customer-choice  {
    .title  {
      background-image: url('~@/assets/img/home/home-customer-choice-light.png');
      background-position: 0 50%;
      background-size: 100% 100%;
    }
    p  {
      color: var(--color-text-1);
    }
    .customers  {
      .item  {
        background: #FFFFFF;
        backdrop-filter: blur(27.2px);
        box-shadow: 0px 0px 24px 0px rgba(15, 17, 16, 0.1);
        border-radius: 4px;
        background-position: 50% 50%;
        background-size: 50%;
        background-repeat: no-repeat;
        font-size: 13px;
        align-items: center;
        color: rgba(255,255,255,0.4);
      }
    }
  }
  .product-advantage  {
    .title  {
      background-image: url('~@/assets/img/home/home-product-advantage-light.png');
      background-position: 0 50%;
      background-size: 100% 100%;
    }
    .main  {
      .left  {
        background: #ffffff;
        .active_bg  {
          border-right: 2px solid rgb(31, 226, 169);
          background: #fff;
          // box-shadow: 0px 0px 24px 0px rgba(15, 17, 16, 1);
          span  {
            color: #00AB7A;
          }
        }
        .item  {
          box-sizing: border-box;
          color: var(--color-text-1);
          border-bottom: 1px solid #E8EAED;
          // opacity: 0.7;
          &:hover  {
            color: #00AB7A;
          }
          span  {
            color: transparent;
            transition: color 0.3s;
          }
        }
        .active  {
          color: #00AB7A;
          span  {
            color: #00AB7A;
          }
        }
      }
      .right  {
        background: #ffffff;
        box-shadow: none;
        background-image: url('~@/assets/img/home/ps-0-light.png');
        background-position: 100% 70%;
        background-size: 40%;
        background-repeat: no-repeat;
        .headline  {
          color: #00AB7A;
        }
        .content  {
          color: var(--color-text-1);
        }
      }
    }
  }
  .typical-scenarios  {
    .title  {
      background-image: url('~@/assets/img/home/home-typical-scenarios-light.png');
      background-position: 0 50%;
      background-size: 100% 100%;
    }
    .tabs  {
      .tsActive  {
        background: #00AB7A;
      }
      .tab  {
        flex: 1;
        border-bottom: 4px solid rgba(1, 171, 121, 0.2);
        background-position: 50% 35%;
        background-size: 56px 56px;
        background-repeat: no-repeat;
        color: var(--color-text-1);
        cursor: pointer;
        span  {
          opacity: 0.7;
        }
        &:nth-child(2)  {
          background-image: url('~@/assets/img/home/home-typical-scenarios-icon1.png');
        }
        &:nth-child(3)  {
          background-image: url('~@/assets/img/home/home-typical-scenarios-icon2.png');
        }
        &:nth-child(4)  {
          background-image: url('~@/assets/img/home/home-typical-scenarios-icon3.png');
        }
      }
      .active  {
        color: #00AB7A;
        span  {
          opacity: 1;
        }
      }
    }
    .card  {
      background: #ffffff;
      box-shadow: none;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      .headline  {
        color: #00AB7A;
      }
      .content  {
        color: var(--color-text-1);
      }
    }
  }
  .box4  {
    .title  {
      background-image: url('~@/assets/img/home/home-service-price-light.png');
      background-position: 0 50%;
      background-size: 100% 100%;
    }
    &-item  {
      background: #ffffff;
      box-shadow: none;
      &:hover  {
        box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.05);
      }
      &:nth-child(1) {
        .box4-item__title {
          color: #FFFFFF;
          background-image: url('~@/assets/img/home/home-servicePrice-mf-light.png');
        }
        .box4-item__btn {
          background: #009e71;
          color: #ffffff;
          &:hover  {
            background: #00ad7c;
          }
        }
      }
      &:nth-child(2) {
        .box4-item__title {
          color: #FFFFFF;
          background-image: url('~@/assets/img/home/home-servicePrice-zy-light.png');
        }
        .box4-item__btn {
          border: 1px solid #DFE1E6;
          color: #242933;
          &:hover  {
            border: 1px solid #DFE1E6;
            color: #242933;
          }
        }
      }
      &:nth-child(3) {
        .box4-item__title {
          color: #523502;
          background-image: url('~@/assets/img/home/home-servicePrice-zyzq-light.png');
        }
        .box4-item__btn {
          border: 1px solid #DFE1E6;
          color: #242933;
          &:hover  {
            border: 1px solid #DFE1E6;
            color: #242933;
          }
        }
      }
      &:nth-child(4) {
        .box4-item__title {
          color: #FFFFFF;
          background-image: url('~@/assets/img/home/home-servicePrice-qy-light.png');
        }
        .box4-item__btn {
          border: 1px solid #DFE1E6;
          color: #242933;
          &:hover  {
            border: 1px solid #DFE1E6;
            color: #242933;
          }
        }
      }
      i {
        color: #00B682;
      }
      &__content  {
        border-bottom: 1px solid #E8EAED;
        color: var(--color-text-1);
      }
      &__list  {
        &--content  {
          color: #525966;
        }
        &--li  {
          color: var(--color-text-1);
        }
      }
      &__btn  {
        background: transparent;
      }
    }
  }
  .home  {
    &-footer  {
      background: #1B1D21;
      box-shadow: inset 0px 1px 0px 0px rgba(38, 40, 51, 1);
      color: #fff !important;
      & :deep(.footer-wrap)  {
        .copyright {
          color: var(--color-text-2) !important;
          .copyright-link  {
            color: var(--color-text-2) !important;
          }
        }
      }
    }
  }
}
</style>
