<template>
    <div>
        <video ref="videoPlayer" class="video-js vjs-default-skin vjs-big-play-centered"></video>
    </div>
</template>

<script setup>
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import { defineProps, onMounted, onBeforeUnmount, ref } from 'vue'
import { isDark, watchSwitchTheme } from 'app/style'
import { isZh } from 'app/i18n'
const props = defineProps({
  options: {
    type: Object,
    default() {
      return {}
    }
  }
})

const player = ref(null)

const videoPlayer = ref()

onMounted(() => {
  player.value = Video(videoPlayer.value, props.options, function onPlayerReady() {
    console.log('onPlayerReady', this)
  })
  watchSwitchTheme((l) => {
    player.value.poster(isDark() ? (isZh() ? require('../component/sandbox.png') : require('../component/sandbox-en.png')) : (isZh() ? require('../component/sandbox-light.png') : require('../component/sandbox-light-en.png')))
  })
})
onBeforeUnmount(() => {
  if (player.value) {
    player.value.dispose()
  }
})
</script>
<style>
  .video-js {
    border-radius: 10px
  }
  .vjs-paused .vjs-big-play-button,
  .vjs-paused.vjs-has-started .vjs-big-play-button {
      display: block;
  }
  .video-js .vjs-big-play-button{
    font-size: 2.5em;
    line-height: 2.3em;
    height: 2.5em;
    width: 2.5em;
    -webkit-border-radius: 2.5em;
    -moz-border-radius: 2.5em;
    border-radius: 2.5em;
    background-color: #73859f;
    background-color: rgba(115,133,159,.5);
    border-width: 0.15em;
    margin-top: -1.25em;
    margin-left: -1.75em;
  }
  /* 中间的播放箭头 */
  .vjs-big-play-button .vjs-icon-placeholder {
      font-size: 1.63em;
  }
  /* 加载圆圈 */
  .vjs-loading-spinner {
      font-size: 2.5em;
      width: 2em;
      height: 2em;
      border-radius: 1em;
      margin-top: -1em;
      margin-left: -1.5em;
  }

  .video-js .vjs-play-progress {
    background-color: #00B682;
  }
  .video-js .vjs-play-progress:before {
    color: #00B682;
  }
  .vjs-poster {
    background-size: cover;
  }
</style>
